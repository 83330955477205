import React, { Component } from 'react';

export default class Third extends Component {
    render() {
        const scrolling = () => {
            document.querySelector('#fourth').scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            });
        };

        return (
            <section id="third" className="panel">
                <div className="content span-5-5">
                    <div className="medium">
                        <h1 className="major">
                            Projects&nbsp;
                            <i className="fas fa-code"></i>
                        </h1>
                        <p className="justifying">
                            The projects that I have to showcase unfortunately do not reflect my current experience, as
                            balancing a full-time job leaves me with limited time to dedicate to developing
                            side-projects. Nevertheless, I strive to stay updated with the latest technologies. My most
                            recent project involves exploring AI, check it out in the next section!
                            <br />
                            My experience mostly lies in{' '}
                            <b>React.js / Next.js / JavaScript with TypeScript, Redux and ReactQuery</b>. Next to these
                            I'm skilled in A/B testing with <b>Unleash</b>, and in automated testing with{' '}
                            <b>Cypress and Jest</b>. I'm also accomplished in design systems using{' '}
                            <b>Figma, MaterialUI, and Storybook</b>. Apart from also exploring mobile app development
                            with <b>Flutter</b>, I learned full-stack web development (React.js and <b>Node.js</b>) in
                            coding bootcamp in Amsterdam.
                        </p>
                    </div>
                    <button className="arrow" onClick={scrolling}>
                        <i className="arrow__icon fas fa-chevron-circle-right"></i>
                    </button>
                </div>
            </section>
        );
    }
}
