import React, { Component } from 'react';
import cv from '../assets/adel_tancsik_cv___2024.pdf';
import table from '../assets/images/table.jpg';

export default class Fifth extends Component {
    render() {
        return (
            <section id="fifth" className="panel spotlight">
                <div className="content span-5-5">
                    <h1 className="major title">Resume</h1>
                    <p>Check out my CV or download it in PDF format.</p>
                    <div className="btn__container">
                        <a
                            href={cv}
                            className="btn btn--white remove-style"
                            rel="noreferrer"
                            target="_blank"
                            alt="View CV"
                            title="View CV"
                        >
                            View
                        </a>
                        <a
                            href={cv}
                            className="btn btn--white remove-style"
                            download="Adél_Tancsik_CV"
                            alt="Download CV"
                            title="Download CV"
                        >
                            Download
                        </a>
                    </div>
                </div>
                <div className="image">
                    <img src={table} alt="Table" id="table" />
                </div>
            </section>
        );
    }
}
