import React, { Component } from 'react';
import pic2 from '../assets/images/Adel.jpg';

export default class Second extends Component {
    render() {
        const scrolling = () => {
            document.querySelector('#third').scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            });
        };
        return (
            <section id="second" className="panel spotlight right">
                <div className="content right span-7-5">
                    <div className="medium">
                        <h1 className="major">About me</h1>
                        <blockquote>
                            “Change is inevitable. Growth is optional.”
                            <br />
                            <span>— John Maxwell</span>
                        </blockquote>
                        <p className="justifying">
                            This quote resonates with me as I reflect on my journey over the past five years, changing
                            career from a Physiotherapist to a Web Developer.
                            <br />
                            My journey led me to pursue a coding bootcamp in Amsterdam at the end of 2019 after a period
                            of autodidactism. Through this adventure, I discovered my passion for coding.
                            <br />
                            Even as I now transition from my current medior front-end developer role at Zoover, my
                            commitment for this career path remains resolute.
                        </p>
                    </div>

                    <button className="arrow" onClick={scrolling}>
                        <i className="arrow__icon fas fa-chevron-circle-right"></i>
                    </button>
                </div>
                <div className="image filtered tinted">
                    <img src={pic2} alt="Adel" id="adel-pic" />
                </div>
            </section>
        );
    }
}
