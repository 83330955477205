import React, { Component, Suspense, lazy } from 'react';
const ProjectCss = lazy(() => import('./ProjectCss'));
const ProjectAi = lazy(() => import('./ProjectAi'));
const FadeInSection = lazy(() => import('./FadeInSection'));

export default class Fourth extends Component {
    render() {
        const { onVisible } = this.props;
        return (
            <Suspense fallback={<div></div>}>
                <section id="fourth" className="panel">
                    <FadeInSection>
                        <ProjectAi onVisible={onVisible}></ProjectAi>
                    </FadeInSection>
                    <FadeInSection>
                        <ProjectCss onVisible={onVisible}></ProjectCss>
                    </FadeInSection>
                </section>
            </Suspense>
        );
    }
}
