import React, { useCallback, useState } from 'react';
import auth from '../assets/images/ai_project/auth.gif';
import generation from '../assets/images/ai_project/generation.gif';
import topup from '../assets/images/ai_project/topup.gif';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const PopupAi = ({ onVisible, isVisible }) => {
    const [projects, setProjects] = useState({
        auth: true,
        generation: false,
        topup: false,
    });

    const toggleAuth = useCallback(() => {
        setProjects({
            auth: true,
            generation: false,
            topup: false,
        });
    }, []);

    const toggleGeneration = useCallback(() => {
        setProjects({
            auth: false,
            generation: true,
            topup: false,
        });
    }, []);

    const toggleTopup = useCallback(() => {
        setProjects({
            auth: false,
            generation: false,
            topup: true,
        });
    }, []);

    return (
        <TransitionGroup component={null}>
            <CSSTransition key={isVisible} classNames="fade" in={true} appear={true}>
                <section className="popup">
                    <div className="popup__content">
                        <button
                            className="btn btn--white remove-style popup__button"
                            onClick={(event) => onVisible(event, 4)}
                        >
                            X
                        </button>

                        <div className="popup__pic--container">
                            <img
                                src={projects.auth ? auth : projects.generation ? generation : topup}
                                alt="Blog post generator platform GIF"
                                className="popup__pic"
                            />
                        </div>

                        <div className="popup__right">
                            <div className="popup__titles">
                                <button
                                    className={`popup__title ${projects.auth && 'popup__title--selected'}`}
                                    onClick={toggleAuth}
                                >
                                    Auth
                                </button>
                                <button
                                    className={`popup__title ${projects.generation && 'popup__title--selected'}`}
                                    onClick={toggleGeneration}
                                >
                                    Blog post
                                </button>
                                <button
                                    className={`popup__title ${projects.topup && 'popup__title--selected'}`}
                                    onClick={toggleTopup}
                                >
                                    Token top-up
                                </button>
                            </div>
                            <div className="popup__text justifying">
                                <p>
                                    Introducing my latest portfolio project: a cutting-edge blog post generator website
                                    powered by AI. Leveraging my expertise in Next.js and TypeScript, and making use of
                                    my newfound knowledge from a Udemy course on OpenAI, MongoDB, and Stripe
                                    integration, I've developed a SaaS solution designed to generate SEO-optimized blog
                                    posts in minutes.
                                </p>
                                <p>
                                    The user authentication is set up through Auth0 - registered users can purchase
                                    tokens using Stripe payment integration, enabling them to unlock the AI-powered blog
                                    post generation feature.
                                </p>
                                <p>Click through the buttons above to see the different flows of the platform.</p>
                            </div>
                            <p className="fourth__text">
                                If you are interested to know more
                                <br />- looking into the code or see the running site itself -
                                <br />
                                I'd be happy to show it during interviews.
                            </p>
                        </div>
                    </div>
                </section>
            </CSSTransition>
        </TransitionGroup>
    );
};

export default PopupAi;
