import React, { Component } from 'react';

export default class First extends Component {
    render() {
        const scrolling = () => {
            document.querySelector('#second').scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            });
        };
        return (
            <section id="first" className="panel">
                <div className="content span-5-5">
                    <h1 className="major title">
                        Hello, my name is
                        <span>
                            <h1 className="name title">Adél Tancsik</h1>
                        </span>
                    </h1>
                    <p>
                        Welcome to my Portfolio!
                        <br />
                        Enjoy exploring, and feel free to connect and contact me on&nbsp;
                        <a href="https://www.linkedin.com/in/adel-tancsik/" target="_blank" rel="noreferrer">
                            Linkedin
                        </a>
                        .
                    </p>
                    <button className="arrow" onClick={scrolling}>
                        <i className="arrow__icon fas fa-chevron-circle-right"></i>
                    </button>
                </div>
            </section>
        );
    }
}
