import React, { Component } from 'react';
import './App.css';
import { Route } from 'react-router-dom';
import $ from 'jquery';
import { CSSTransition } from 'react-transition-group';
import Intro from './components/Intro';
import First from './components/First';
import Second from './components/Second';
import Third from './components/Third';
import Fourth from './components/Fourth';
import PopupCss from './components/PopupCss';
import PopupAi from './components/PopupAi';
import FadeInSection from './components/FadeInSection';
import Fifth from './components/Fifth';
import Sixth from './components/Sixth';

class App extends Component {
    componentDidMount() {
        // Play initial animations on page load, then stop.
        $(document).ready(function () {
            $(window).on('load', function () {
                window.setTimeout(function () {
                    $('#spinner').removeClass('lds-dual-ring');
                }, 100);
            });
        });
    }

    state = {
        visible5: false,
        visible4: false,
    };

    onVisible = (event, num) => {
        event.preventDefault();
        switch (num) {
            case 5:
                !this.state.visible5 ? this.setState({ visible5: true }) : this.setState({ visible5: false });
                break;
            case 4:
                !this.state.visible4 ? this.setState({ visible4: true }) : this.setState({ visible4: false });
                break;
            default:
                !this.state.visible1 ? this.setState({ visible1: true }) : this.setState({ visible1: false });
                break;
        }
    };

    render() {
        return (
            <CSSTransition in={true} appear={true} timeout={2000} classNames="fade">
                <div id="app">
                    <Route path="/" exact component={Intro} />
                    <Route path="/" exact component={First} />
                    <Route path="/" exact component={Second} />
                    <FadeInSection>
                        <Route path="/" exact component={Third} />
                    </FadeInSection>
                    <FadeInSection>
                        <Route path="/" render={(props) => <Fourth {...props} onVisible={this.onVisible} />} />
                    </FadeInSection>
                    <FadeInSection>
                        <Route path="/" exact component={Fifth} />
                    </FadeInSection>
                    <FadeInSection>
                        <Route path="/" exact component={Sixth} />
                    </FadeInSection>
                    {this.state.visible5 ? (
                        <Route
                            path="/"
                            render={(props) => (
                                <PopupCss {...props} onVisible={this.onVisible} visible5={this.state.visible5} />
                            )}
                        />
                    ) : (
                        ''
                    )}
                    {this.state.visible4 ? (
                        <Route
                            path="/"
                            render={(props) => (
                                <PopupAi {...props} onVisible={this.onVisible} isVisible={this.state.visible4} />
                            )}
                        />
                    ) : (
                        ''
                    )}
                    {/* <div className="lds-dual-ring" id="spinner"></div> */}
                </div>
            </CSSTransition>
        );
    }
}

export default App;
